import { FacebookEmbed, InstagramEmbed, PinterestEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { platformEnumBycyabraPlatformCode } from '../../../app/common/options/platformOptions';

export type IProps = {
    url: string,
    platform: string,
    width: string,
    height: string,
    isCyabra: boolean
}

function SocialFrame(props: IProps) {
    let platform = props.isCyabra ? platformEnumBycyabraPlatformCode.get(props.platform) : props.platform;
    const _props = {
        url: props.url,
        width: props.width,
        height: props.height,
        style: { overflowY: 'auto' as const }
    };
    
    if (platform === "Facebook") {
        return (<FacebookEmbed {..._props} />)
    }
    else if (platform === "Instagram") {
        return (<InstagramEmbed {..._props} />)
    }
    else if (platform === "Linkdin") {
        return (<></>)
    }
    else if (platform === "Pinterest") {
        return (<PinterestEmbed {..._props} />)
    }
    else if (platform === "Tiktok") {
        return (<TikTokEmbed {..._props} />)

    }
    else if (platform === "Twitter") {
        return (<TwitterEmbed {..._props} /> )
    }
    else if (platform === "Youtube") {
        return (<YouTubeEmbed {..._props} />)
    }
    else {
        return (<></>)
    }
}

export default SocialFrame;
